import React, { Component } from 'react'
import { Container, Header, Segment, Button, Icon, Dimmer, Loader, Divider, Table, Menu, Label } from 'semantic-ui-react'

class App extends Component {
  constructor () {
    super()
    this.state = {}
    this.getLeagues = this.getLeagues.bind(this)
    this.getLeague = this.getLeague.bind(this)
    this.getMatchResults = this.getMatchResults.bind(this)
  }

  componentDidMount () {
    this.getLeagues()
  }

  fetch (endpoint) {
    return window.fetch(endpoint)
      .then(response => response.json())
      .catch(error => console.log(error))
  }

  getLeagues () {
    this.fetch('/api/leagues')
      .then(leagues => {
        if (leagues.length) {
          this.setState({leagues: leagues})
          this.getLeague(leagues[0].id)
        } else {
          this.setState({leagues: []})
        }
      })
  }

  getMatchResults (id) {
    this.fetch(`/api/league/${id}/match_results`)
      .then(match_results => {
        if (match_results.length) {
          this.setState({match_results: match_results})
          this.getMatchResult(match_results[0].id)
        } else {
          this.setState({match_results: []})
        }
      })
  }

  getMatchResult (id) {
    this.fetch(`/api/match_results/${id}`)
      .then(match_result => this.setState({match_result: match_result}))
  }

  getLeague (id) {
    this.fetch(`/api/leagues/${id}`)
      .then(league => this.setState({league: league}))
  }

  render () {
    let {leagues, league} = this.state
    return leagues
      ? <Container text>
        <Header as='h2' icon textAlign='center' color='red'>
          <Icon name='unordered list' circular />
          <Header.Content>
            Grubhub Leagues
          </Header.Content>
        </Header>
        <Divider hidden section />
        {leagues && leagues.length
          ? <Button.Group color='red' fluid widths={leagues.length}>
            {Object.keys(leagues).map((key) => {
              return <Button active={league && league.id === leagues[key].id} fluid key={key} onClick={() => this.getLeague(leagues[key].id)}>
                {leagues[key].set_abbreviation}
              </Button>
            })}
          </Button.Group>
          : <Container textAlign='center'>No leagues found.</Container>
        }
        <Divider section />
        {league &&
          <Container>
            <Header as='h2'>{league.name}</Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Player</Table.HeaderCell>
                        <Table.HeaderCell>Record</Table.HeaderCell>
                        <Table.HeaderCell>Points</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Cell</Table.Cell>
                        <Table.Cell>Cell</Table.Cell>
                        <Table.Cell>Cell</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Cell</Table.Cell>
                        <Table.Cell>Cell</Table.Cell>
                        <Table.Cell>Cell</Table.Cell>
                      </Table.Row>
                    </Table.Body>

                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan='3'>
                          <Menu floated='right' pagination>
                            <Menu.Item as='a' icon>
                              <Icon name='chevron left' />
                            </Menu.Item>
                            <Menu.Item as='a'>1</Menu.Item>
                            <Menu.Item as='a'>2</Menu.Item>
                            <Menu.Item as='a'>3</Menu.Item>
                            <Menu.Item as='a'>4</Menu.Item>
                            <Menu.Item as='a' icon>
                              <Icon name='chevron right' />
                            </Menu.Item>
                          </Menu>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>

            }
          </Container>
        }
      </Container>
      : <Container text>
        <Dimmer active inverted>
          <Loader content='Loading' />
        </Dimmer>
      </Container>
  }
}

export default App